<template>
<div v-if="count&&loginShow">
    <MasterHeader />
    <v-container>
        <h1 class="text-center mb-10 page_title">よくある質問 設定</h1>
        <p class="text-center">よくある質問を設定できます</p>
        <div class="text-right">
            <v-btn x-large color="blue" dark class="mr-5 mb-3" @click="save()">保存</v-btn>
        </div>
        <div>
            <v-form ref="form">
                <div class="faq_box mx-auto">
                    <div class="ma-2 pt-3 px-2 border mt-4" v-for="i in count" :key="i">
                        <p class="text-center">アイテム{{i}}</p>
                        <v-text-field outlined label="サービス名" v-model="questions[i-1]" type="text" :rules="requredRules" />
                        <v-textarea v-model="answers[i-1]" label="本文*" outlied :rules="requredRules" class="mt-5" auto-grow outlined rows="12" row-height="15" maxlength="5000"></v-textarea>
                        <div class="text-right">
                            <v-btn text color="gray" @click="deleted(i-1)">削除</v-btn>
                        </div>
                    </div>
                    <v-btn color="red" @click="count+=1" dark class="bold f-12 mt-2">＋</v-btn>
                </div>
            </v-form>
        </div>
    </v-container>
</div>
</template>

<script>
import axios from "axios"
import Login from '@/mixins/login'
import MasterHeader from '@/components/MasterHeader.vue'
export default {
    mixins: [Login],
    data() {
        return {
            faqs: 0,
            questions: [],
            anwers: [],
            count: 0,
            requredRules: [
                v => !!v || '入力必須です',
            ],
        }
    },
    components: {
        MasterHeader
    },
    async mounted() {

        const response = await axios.post('/master/faq')
        this.faqs = response.data.faqs
        this.questions = this.faqs.map(item => item.question)
        this.answers = this.faqs.map(item => item.answer)

        this.count = this.answers.length
    },
    methods: {
        async save() {
            this.$refs.form.validate()
            if (!this.$refs.form.validate()) {
                return false
            }
            const data = {
                answer: JSON.stringify(this.answers),
                question: JSON.stringify(this.questions),
            }

            await axios.post('/master/faq/save', data)
            window.location.href = "/master/faq";
        },
        deleted(val) {
            this.answers.splice(val, 1)
            this.questions.splice(val, 1)
            this.count -= 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/master/style.scss";
@import "@/assets/sass/master/faq.scss";
</style>
